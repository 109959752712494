<template>
  <div v-if="!formSubmittedSuccessfully" class="container">
    <div class="container-payment-info">
      <h2 class="title title-underline title-offset animation-underline">
        {{ cmsStore.getChkOutForm.fgOrderDetails }}
      </h2>

      <p class="section-title">
        {{ cmsStore.getChkOutForm.fgBillingInformation }}
      </p>

      <div>
        <p class="hint">{{ cmsStore.getChkOutForm.fgAllFieldsMarkedWith }} <span class="star">*</span>
          {{ cmsStore.getChkOutForm.fgAreRequired }}.</p>
      </div>

      <div class="input-row">
        <Input type="text" v-model="firstName" :error="v$.firstName">
        {{ cmsStore.getChkOutForm.fgFirstName }}<span class="star">*</span>
        </Input>

        <Input type="text" v-model="lastName" :error="v$.lastName">
        {{ cmsStore.getChkOutForm.fgLastName }} <span class="star">*</span>
        </Input>
      </div>

      <div class="input-row">
        <Input type="text" v-model="billingAddress1" :error="v$.billingAddress1">
        {{ cmsStore.getChkOutForm.fgStreetAddress1 }} <span class="star">*</span>
        </Input>

        <Input type="text" v-model="billingAddress2" :error="v$.billingAddress2">
        {{ cmsStore.getChkOutForm.fgStreetAddress2 }}
        </Input>
      </div>

      <div class="input-row">
        <Input type="text" v-model="billingCity" :error="v$.billingCity">
        {{ cmsStore.getChkOutForm.fgCity }} <span class="star">*</span>
        </Input>

        <!-- Non-USA countries will have a regular text input for the 'State' data field -->
        <Input v-if="!isUS(billingCountry)" type="text" v-model="billingStateNonUSA" :error="v$.billingStateNonUSA">
        {{ cmsStore.getChkOutForm.fgState }} <span class="star">*</span>
        </Input>
        <Select v-if="isUS(billingCountry)" :list="apiCallsStore.stateList" @select-id="setStateAbbv"
          v-model="billingState" :error="v$.billingState">
          {{ cmsStore.getChkOutForm.fgState }} <span class="star">*</span>
        </Select>

      </div>

      <div class="input-row">
        <Input type="text" :isDisabled="!isBillingCountryUSA" v-model="billingZip" :error="v$.billingZip">
        {{ cmsStore.getChkOutForm.fgZipCode }} <span v-if="isBillingCountryUSA" class="star">*</span>
        </Input>

        <Select :list="apiCallsStore.countryList" @select-item="setCountry" v-model="billingCountry"
          :error="v$.billingCountry">
          {{ cmsStore.getChkOutForm.fgCountry }} <span class="star">*</span>
        </Select>
      </div>

      <div class="input-row input-row-checkbox">
        <input type="checkbox" @click="toggleDifferentBillingShippingAddresses"
          v-model="billingAndShippingAreDifferent" />
        <label for="checkbox" @click="toggleDifferentBillingShippingAddresses">
          {{ cmsStore.getChkOutForm.fgPleaseCheckIfShippingAddress }}
        </label>
      </div>

      <div class="input-row">
        <p v-if="!isBillingCountryUSA" class="notice-us-only">Items may only be shipped to valid, United States
          addresses</p>
      </div>

      <template v-if="billingAndShippingAreDifferent">

        <div class="input-row">
          <Input type="text" v-model="shippingAddress1" :error="v$.shippingAddress1">
          {{ cmsStore.getChkOutForm.fgShippingAddress1 }} <span class="star">*</span>
          </Input>

          <Input type="text" v-model="shippingAddress2" :error="v$.shippingAddress2">
          {{ cmsStore.getChkOutForm.fgShippingAddress2 }}
          </Input>
        </div>

        <div class="input-row">
          <Input type="text" v-model="shippingCity" :error="v$.shippingCity">
          {{ cmsStore.getChkOutForm.fgShippingCity }} <span class="star">*</span>
          </Input>

          <Select v-if="isUS(shippingCountry)" :list="apiCallsStore.stateList" @select-item="setShippingState"
            @select-id="setShippingStateAbbv" v-model="shippingState" :error="v$.shippingState">
            {{ cmsStore.getChkOutForm.fgShippingState }} <span class="star">*</span>
          </Select>
        </div>

        <div class="input-row">
          <Input type="text" v-model="shippingZip" :error="v$.shippingZip">
          {{ cmsStore.getChkOutForm.fgShippingZipCode }} <span class="star">*</span>
          </Input>

          <Select :isDisabled="true" :list="apiCallsStore.countryList" @select-item="setCountry"
            v-model="shippingCountry" :error="v$.shippingCountry">
            {{ cmsStore.getChkOutForm.fgShippingCountry }} <span class="star">*</span>
          </Select>
        </div>

      </template>

      <div class="input-row">
        <Input type="text" v-model="email" :error="v$.email">
        {{ cmsStore.getChkOutForm.fgEmail }} <span class="star">*</span>
        </Input>

        <Input type="tel" v-model="phone" :error="v$.phone"> {{ cmsStore.getChkOutForm.fgPhone }} </Input>
      </div>


      <p class="section-title">
        {{ cmsStore.getChkOutForm.fgPaymentInformation }}
      </p>

      <div v-if="paymentType === 'creditCard'" class="input-row">
        <Input type="text" v-model.trim="cardNumber" :error="v$.cardNumber">
        {{ cmsStore.getChkOutForm.fgCreditCardNumber }}
        </Input>
      </div>

      <div v-if="paymentType === 'creditCard'" class="input-row">
        <Select :list="months" @select-item="setCardMonth" v-model="cardMonth" :error="v$.cardMonth">
          {{ cmsStore.getChkOutForm.fgMonth }}
        </Select>

        <Select :list="years" @select-item="setCardYear" v-model="cardYear" :error="v$.cardYear">
          {{ cmsStore.getChkOutForm.fgYear }}
        </Select>
      </div>

      <div v-if="paymentType === 'creditCard'" class="input-row input-row--short">
        <Input type="text" v-model.trim="cardCCV" :error="v$.cardCCV">
        {{ cmsStore.getChkOutForm.fgCCV }}
        </Input>
      </div>

      <div class="error-row">
        <p v-if="formErrors" class="register-error">{{ cmsStore.getChkOutForm.fgErrorsInForm }}</p>
        <div class="" v-if="registerError.length > 0" v-for="error in registerError">
          <p class="register-error">{{ error }}</p>
        </div>
      </div>

      <p v-if="formActivelySubmitting" style="font-size: 18px;">
        <strong>
          Processing Purchase, please be patient...
        </strong>
      </p>

      <button v-if="!productStore.getIsCartEmpty" class="button btn btn-default"
        v-bind:class="{ 'form-actively-submitting': formActivelySubmitting }" @click.prevent="formSubmit">
        {{ cmsStore.getChkOutForm.fgSubmit }}
      </button>
    </div>

    <div class="container-cart-summary">
      <h4>{{ cmsStore.getChkOutForm.fgOrderSummaryOrder }}</h4>

      <div class="cart-summary-card">
        <div class="cart-summary-header">
          <div class="tbl-row">
            <p class="tbl-1"><strong>{{ productStore.getCartLength }} {{ cmsStore.getChkOutForm.fgItems }}</strong></p>
            <p class="tbl-2"><strong>{{ cmsStore.getChkOutForm.fgQty }}</strong></p>
          </div>

        </div>
        <div class="cart-summary-body">
          <div v-for="cartItem in productStore.cartComplete" class="tbl-row">
            <p class="tbl-1 p-smaller">{{ productStore.getProductById(cartItem.specialProductId.toString()).title }}</p>
            <p class="tbl-2">{{ cartItem.quantity }}</p>
            <p class="tbl-3">
              {{ this.showFormattedPrice((cartItem.price * 1) * (cartItem.quantity * 1)) }}
            </p>
          </div>
        </div>
        <div class="cart-summary-footer">
          <div class="tbl-row">
            <p class="tbl-footer-1"><strong>{{ cmsStore.getChkOutForm.fgTotal }}</strong></p>
            <p class="tbl-footer-2">
              <strong>
                {{ this.showFormattedPrice(productStore.getCartTotal()) }}
              </strong>
            </p>
          </div>
        </div>

      </div>

      <router-link :to="{ name: 'Cart' }">
        <h4 class="cart-summary-edit">{{ cmsStore.getChkOutForm.fgEditCart }} <i class="fa-solid fa-pencil"></i></h4>
      </router-link>

    </div>

  </div>

  <div v-if="formSubmittedSuccessfully && emailSentSuccessfully" class="container container-thank-you" ref="thank-you">
    <div class="info">
      <p>{{ cmsStore.getChkOutForm.fgThankYou }}, {{ firstName }} {{ cmsStore.getChkOutForm.fgForYourPurchase }}</p>
      <p class="extra-margin-bottom">{{ cmsStore.getChkOutForm.fgWeEmailedReceipt }} {{ email }}</p>
      <router-link :to="{ name: 'Friend' }">
        <button class="btn btn-alternate btn-darker">{{ cmsStore.getChkOutForm.fgBackToHome }}</button>
      </router-link>
    </div>
  </div>

  <div v-if="formSubmittedSuccessfully && !emailSentSuccessfully" class="container container-thank-you" ref="thank-you-no-email">
    <div class="info">
      <p>{{ cmsStore.getChkOutForm.fgThankYou }}, {{ firstName }} {{ cmsStore.getChkOutForm.fgForYourPurchase }}</p>
      <p class="extra-margin-bottom">{{ cmsStore.getChkOutForm.fgPurchaseSuccessProblemWithEmail }} {{billingAuthNo}}. {{cmsStore.getChkOutForm.fgPleaseSaveForYourRecords}}</p>
      <router-link :to="{ name: 'Friend' }">
        <button class="btn btn-alternate btn-darker">{{ cmsStore.getChkOutForm.fgBackToHome }}</button>
      </router-link>
    </div>
  </div>
</template>

<script>
import Input from "@/components/Input";
import Select from "@/components/Select";
import { storeMixin } from "@/mixins/storeMixin"
import { mapStores } from "pinia";
import { useApiCallsStore } from "@/stores/apiCallsStore"
import { useAuthStore } from "@/stores/authStore"
import { useCmsStore } from "@/stores/cmsStore";
import { useDesignStore } from "@/stores/designStore";
import { useProductStore } from "@/stores/productStore"
import useVuelidate from "@vuelidate/core";
import { email, helpers, maxLength, minLength, minValue, numeric, required, requiredIf } from '@vuelidate/validators'

export default {
  name: "CheckoutForm",
  mixins: [storeMixin],
  components: { Input, Select },
  setup: () => ({ v$: useVuelidate({ $lazy: false, $autoDirty: true }) }),
  data() {
    return {
      isProduction: false,
      formActivelySubmitting: false,
      formErrors: false,
      isBillingCountryUSA: true,
      registerError: [],
      billingAndShippingAreDifferent: true,
      billingAddress1: '',
      billingAddress2: '',
      billingAuthNo: '',
      billingCity: '',
      billingState: "",
      billingStateAbbv: "",
      billingStateNonUSA: "",
      billingZip: "",
      billingCountry: 'United States',
      confirmPassword: '',
      firstName: '',
      email: '',
      lastName: '',
      newPassword: '',
      phone: '',
      shippingAddress1: '',
      shippingAddress2: '',
      shippingCity: '',
      shippingCountry: 'United States',
      shippingState: '',
      shippingStateAbbv: '',
      shippingStateNonUSA: '',
      shippingZip: '',
      friendNumber: "",
      donationAmount: null, // This is the total value of the shopping cart
      givingOptions: "oneTime", // `monthly` or `oneTime`
      paymentType: "creditCard", // `creditCard` or `bankDraft`
      cardNumber: "",
      cardYear: "",
      cardMonth: "",
      cardCCV: "",
      checkRoutingNumber: "",
      checkAccountNumber: "",
      checked: true,
      years: [],
      months: [],
      giving_form: {},
      localStateUpdated: false,
      formSubmittedSuccessfully: false,
      emailSentSuccessfully: false,
    };
  },
  computed: {
    ...mapStores(useApiCallsStore, useAuthStore, useCmsStore, useDesignStore, useProductStore),
  },
  async created() {
    this.formSubmittedSuccessfully = false
    this.emailSentSuccessfully = false
    this.months = this.buildCreditCardExpirationMonth()
    this.years = this.buildCreditCardExpirationYear()

    await this.getStateAndCountryLists()
      .then(results => {
        if (results) {
          this.buildLocalStateFromStore()
        }
      })
  },
  mounted() {
    this.designStore.setIsLogo(true);

    const isValidShoppingCartTotal = this.validateCartPrice()

    if (!isValidShoppingCartTotal) return false

    if (process.env.NODE_ENV && process.env.NODE_ENV === 'production') this.isProduction = true
  },
  methods: {
    async apiCallToPurchase(donation) {
      try {
        return await this.apiCallsStore.makePurchase(donation)
          .then(response => {
            return response
          })
      } catch (e) {
        console.error(e)
      }
    },

    buildPurchaseJson() {
      const isValidShoppingCartTotal = this.validateCartPrice()

      if (!isValidShoppingCartTotal) return false

      this.setShippingInfo()
      const tempDonationPersonal = this.buildPersonalInfo(this)
      const tempDonationAddress = this.buildAddressInfo(this)
      const tempDonationPayment = this.buildPaymentInfo(this)

      return Object.assign({}, tempDonationPersonal, tempDonationAddress, tempDonationPayment)
    },
    async buildLocalStateFromStore() {
      this.billingAddress1 = this.authStore.userDetails.address1
      this.billingAddress2 = this.authStore.userDetails.address2
      this.billingCity = this.authStore.userDetails.city
      this.billingCountry = this.authStore.userDetails.country
      this.firstName = this.authStore.userDetails.firstName
      this.email = this.authStore.userDetails.email
      this.lastName = this.authStore.userDetails.lastName
      this.phone = this.authStore.userDetails.phone
      this.billingZip = this.authStore.userDetails.zip

      if (this.isUS(this.billingCountry)) {
        this.isBillingCountryUSA = true
        const userState = this.apiCallsStore.stateList.filter(state => state.abbreviation === this.authStore.userDetails.state)

        if (userState && userState.length === 1) {
          this.billingState = userState[0].name
          this.billingStateAbbv = userState[0].abbreviation
        } else {
          this.billingState = this.authStore.userDetails.state
        }
      } else {
        this.isBillingCountryUSA = false
        this.billingStateNonUSA = this.authStore.userDetails.state
      }

      this.localStateUpdated = true
    },
    async formSubmit() {
      if (this.formActivelySubmitting) return false

      this.setFormErrorMessage(false) // reset form error message
      this.setRegisterErrorMessage() // reset register error message
      this.setFormBeingActivelySubmitted(true)

      let loader = this.$loading.show();

      const isFormValid = await this.v$.$validate();

      try {
        if (isFormValid) {
          const purchase = this.buildPurchaseJson()
          const response = await this.apiCallToPurchase(purchase)
          await this.handleServerResponse(response)
        } else {
          this.setFormErrorMessage(true)
          return false
        }
      } catch (e) {
        console.error('Error in CheckoutForm.vue', e)
      } finally {
        loader.hide()
        this.setFormBeingActivelySubmitted(false)
      }
    },
    async handleServerResponse(res) {
      let errMessage = "There was an issue submitting your Store Purchase, please try again."

      if (res && res.billingAuthNo) {
        this.billingAuthNo = res.billingAuthNo
        this.formSubmittedSuccessfully = true
        await this.productStore.getCartItems(this.authStore.getRegistrationId, true)

        if (res.purchaseEmailSent === false) {
          this.emailSentSuccessfully = false
          this.scrollToElement('thank-you-no-email')
        } else {
          this.emailSentSuccessfully = true
          this.scrollToElement('thank-you')
        }

        return true
      }

      if (res && typeof (res) === 'string') {
        errMessage = res
      } else if (res && Array.isArray(res)) {
        this.setFormErrorMessage(true)
        return this.setRegisterErrorMessage(res)
      }
      this.setRegisterErrorMessage([errMessage])
    },
    async getStateAndCountryLists() {
      return await this.apiCallsStore.GetStatesAndCountries(true, this.billingCountry)
    },
    scrollToElement(refName) {
      const titlePageHeight = this.designStore.heightTitlePage
      const element = this.$refs[refName];
      const top = element.offsetTop + titlePageHeight;

      window.scrollTo(0, top);
    },
    setShippingInfo() {
      if (this.billingAndShippingAreDifferent) {

      } else {
        this.shippingAddress1 = this.billingAddress1
        this.shippingAddress2 = this.billingAddress2
        this.shippingCity = this.billingCity
        this.shippingState = this.billingState
        this.shippingStateAbbv = this.billingStateAbbv
        this.shippingStateNonUSA = this.billingStateNonUSA
        this.shippingZip = this.billingZip
        this.shippingCountry = this.billingCountry
      }
    },
    setFormErrorMessage(status) {
      this.formErrors = status
    },
    setRegisterErrorMessage(message) {
      this.registerError = []
      if (message) {
        message.map(msg => this.registerError.push(msg))
      }
    },
    setFormBeingActivelySubmitted(status) {
      this.formActivelySubmitting = status
    },
    setShippingState(payload) {
      this.shippingState = payload.name
    },
    setShippingStateAbbv(payload) {
      this.shippingStateAbbv = payload
    },
    setState(payload) {
      this.billingState = payload.name
    },
    setStateAbbv(payload) {
      this.billingStateAbbv = payload
    },
    setCountry(payload) {
      this.billingCountry = payload.name

      if (payload.name && this.isUS(payload.name)) {
        this.isBillingCountryUSA = true
        this.billingStateNonUSA = ''
        this.billingZip = ''
      } else {
        this.isBillingCountryUSA = false
        this.billingState = ''
        this.billingStateAbbv = ''
        this.billingZip = ''
        this.billingAndShippingAreDifferent = true
      }
    },
    setCardType(payload) {
      this.cardType = payload.name;
    },
    setCardMonth(payload) {
      this.cardMonth = payload.name;
    },
    setCardYear(payload) {
      this.cardYear = payload.name;
    },
    toggleDifferentBillingShippingAddresses() {
      if (!this.isBillingCountryUSA) {
        // Need to reset it and then set it again to trigger a re-render
        this.billingAndShippingAreDifferent = false
        this.billingAndShippingAreDifferent = true
        return false
      }
      this.billingAndShippingAreDifferent = !this.billingAndShippingAreDifferent
    },
  },
  validations() {
    const notExpiredCreditCard = value => {
      let currentMonth = new Date().getMonth() + 1 // add `1` because it is zero-based
      let currentYear = new Date().getFullYear()
      let parsedMonth = parseInt(value)

      if (currentYear === parseInt(this.cardYear) && parsedMonth) {
        if (parsedMonth < currentMonth) {
          return false
        }
      }
      return true
    }
    const requireZipForUSAOnly = value => {
      if (this.isUS(this.billingCountry) && !value) {
        return false
      } else if (!this.isUS(this.billingCountry)) return true

      return true
    }
    const validStateForNonUSA = value => {
      if (!value && !this.isUS(this.billingCountry)) return false
      if (!this.isUS(this.billingCountry)) {
        if (value.length > 1) return true
      } else if (this.isUS(this.billingCountry)) return true

      return false
    }
    const validStateForUSA = value => {
      if (!value && this.isBillingCountryUSA) return false
      if (this.isUS(this.billingCountry)) {
        const userState = this.apiCallsStore.stateList.filter(state => state.abbreviation === value || state.name === value)
        if (userState && userState.length > 0) return true
      } else if (!this.isUS(this.billingCountry)) return true

      return false
    }

    return {

      billingCity: {
        required: helpers.withMessage('City is required', required),
        maxLengthValue: helpers.withMessage('City cannot be longer than 50 characters', maxLength(50)),
      },
      email: {
        required: helpers.withMessage('Email is required', required),
        maxLengthValue: helpers.withMessage('Email cannot be longer than 100 characters', maxLength(100)),
        email,
      },
      firstName: {
        required: helpers.withMessage('First name is required', required),
        maxLengthValue: helpers.withMessage('First name cannot be longer than 50 characters', maxLength(50)),
      },
      lastName: {
        required: helpers.withMessage('Last name is required', required),
        maxLengthValue: helpers.withMessage('Last name cannot be longer than 50 characters', maxLength(50)),
      },
      billingState: {
        validStateForUSA: helpers.withMessage('State selection is invalid', validStateForUSA),
      },
      billingStateNonUSA: {
        validStateForNonUSA: helpers.withMessage('State selection is invalid', validStateForNonUSA),
      },
      billingZip: {
        requireZipForUSAOnly: helpers.withMessage('Zip code is required', requireZipForUSAOnly),
      },
      billingCountry: {
        required: helpers.withMessage('Country is required', required),
      },
      billingAddress1: {
        required: helpers.withMessage('Address is required', required),
        maxLengthValue: helpers.withMessage('Address 1 cannot be longer than 200 characters', maxLength(200)),
      },
      donationAmount: {
        required: helpers.withMessage('Donation Amount is required', required),
        minValueAmount: helpers.withMessage('Donation amount must be at least $1', minValue(1)),
      },
      givingOptions: {
        required: helpers.withMessage('Giving Options (frequency) is required', required),
      },
      paymentType: {
        required: helpers.withMessage('Payment type is required', required),
      },
      cardNumber: {
        requiredIfCCPaymentType: helpers.withMessage(
          'Credit card number is required',
          requiredIf(this.paymentType === 'creditCard')
        ),
        numeric: helpers.withMessage('Only numbers are allowed', numeric),
        minLengthValue: helpers.withMessage('At least 15 numbers are required', minLength(15)),
        maxLengthValue: helpers.withMessage('At most 16 numbers are required', maxLength(16)),
      },
      cardMonth: {
        requiredIfCCPaymentType: helpers.withMessage(
          'Credit card month is required',
          requiredIf(this.paymentType === 'creditCard')
        ),
        notExpiredCreditCard: helpers.withMessage('Credit card month is expired', notExpiredCreditCard),
      },
      cardYear: {
        requiredIfCCPaymentType: helpers.withMessage(
          'Credit card year is required',
          requiredIf(this.paymentType === 'creditCard')
        ),
      },
      cardCCV: {
        requiredIfCCPaymentType: helpers.withMessage(
          'Credit card CVV is required',
          requiredIf(this.paymentType === 'creditCard')
        ),
        numeric: helpers.withMessage('Only numbers are allowed', numeric),
        minLengthValue: helpers.withMessage('At least 3 numbers are required', minLength(3)),
        maxLengthValue: helpers.withMessage('At most 4 numbers are required', maxLength(4)),
      },
      checkAccountNumber: {
        requiredIfACHPaymentType: helpers.withMessage(
          'Checking account number is required',
          requiredIf(this.paymentType === 'bankDraft')
        ),
        numeric: helpers.withMessage('Only numbers are allowed', numeric),
        minLengthValue: helpers.withMessage('At least 4 numbers are required', minLength(4)),
        maxLengthValue: helpers.withMessage('At most 17 numbers are required', maxLength(17)),
      },
      checkRoutingNumber: {
        requiredIfACHPaymentType: helpers.withMessage(
          'Checking routing number is required',
          requiredIf(this.paymentType === 'bankDraft')
        ),
        numeric: helpers.withMessage('Only numbers are allowed', numeric),
        minLengthValue: helpers.withMessage('At least 4 numbers are required', minLength(4)),
        maxLengthValue: helpers.withMessage('At most 10 numbers are required', maxLength(10)),
      },
      shippingAddress1: {
        requiredIfDiffThanBilling: helpers.withMessage(
          'Shipping Address 1 is required',
          requiredIf(this.billingAndShippingAreDifferent)
        ),
        maxLengthValue: helpers.withMessage('Address 1 cannot be longer than 200 characters', maxLength(200)),
      },
      shippingCity: {
        requiredIfDiffThanBilling: helpers.withMessage(
          'Shipping City is required',
          requiredIf(this.billingAndShippingAreDifferent)
        ),
        maxLengthValue: helpers.withMessage('Shipping City cannot be longer than 50 characters', maxLength(50)),
      },
      shippingCountry: {
        requiredIfDiffThanBilling: helpers.withMessage(
          'Shipping Country is required',
          requiredIf(this.billingAndShippingAreDifferent)
        ),
      },
      shippingState: {
        requiredIfDiffThanBilling: helpers.withMessage(
          'Shipping State is required',
          requiredIf(this.billingAndShippingAreDifferent)
        ),
      },
      shippingZip: {
        requiredIfDiffThanBilling: helpers.withMessage(
          'Shipping Zip is required',
          requiredIf(this.billingAndShippingAreDifferent)
        ),
      },
    }
  }
};
</script>


<style lang="scss" scoped>
@import "../assets/scss/abstracts/_color.scss";
@import "../assets/scss/abstracts/_variables.scss";

.container {
  position: relative;
  background: $white1;
  padding: 50px 50px 50px 50px;
  display: flex;
  width: 100%;

  .container-payment-info,
  .container-cart-summary {
    padding: 40px;
  }

  .container-payment-info {
    width: 65%;
    padding-right: 80px;
  }

  .container-cart-summary {
    width: 35%;
    padding-top: 100px;
  }

  @media screen and (max-width: 768px) {
    :deep .title-label {
      margin-bottom: 0;
    }

    :deep .select-container .input {
      margin-bottom: 0;
    }

    .container {
      flex-direction: column;
    }

    .container-payment-info,
    .container-cart-summary {
      width: 100%;
    }

    .container-payment-info {
      padding: 0;
      order: 2;
    }

    .container-cart-summary {
      padding: 0;
      order: 1;
    }

    .cart-summary-card .cart-summary-header,
    .cart-summary-card .cart-summary-body,
    .cart-summary-card .cart-summary-footer {
      padding: 8px 10px;
    }

  }

  :deep .select-container .input {
    margin-top: 6px;
  }


  .cart-summary-card {
    background-color: #fff;
    border: 2px solid $blue-darkest;
  }

  .cart-summary-header,
  .cart-summary-body,
  .cart-summary-footer {
    padding: 10px 20px;
  }

  .cart-summary-body {
    border-top: 1px solid $grey;
    border-bottom: 1px solid $grey;
    //min-height: 300px;
  }

  .cart-summary-header .tbl-row p {
    margin-top: 8px;
    margin-bottom: 0;
  }

  .cart-summary-edit {
    margin-top: 18px;
    text-align: right;
  }

  .tbl-row {
    width: 100%;
  }

  .cart-summary-body>.tbl-row {
    padding-bottom: 8px;
  }

  .cart-summary-body .tbl-row:last-of-type {
    margin-bottom: 30px;
  }

  .tbl-row p {
    display: inline-block;
  }

  p.tbl-1 {
    width: 65%;
    vertical-align: top;
  }

  p.tbl-2 {
    width: 10%;
  }

  p.tbl-3 {
    width: 25%;
  }

  p.tbl-2,
  p.tbl-3 {
    text-align: right;
    vertical-align: middle;
    margin-bottom: 25px;
  }

  p.tbl-footer-1,
  p.tbl-footer-2 {
    width: 50%;
    margin-bottom: 1px;
  }

  p.tbl-footer-1 {
    font-size: 22px;
  }

  p.tbl-footer-2 {
    width: 50%;
    font-size: 36px;
    text-align: right;
  }

  :deep p {
    font-size: 18px;
    margin-bottom: 0;
  }

  p.p-smaller {
    font-size: 16px;
  }

  @media screen and (max-width: 1024px) {
    padding: 50px;

    p {
      font-size: 15px;
    }
  }

  @media screen and (max-width: 768px) {
    padding: 25px;
  }

  @media screen and (max-width: 425px) {
    padding: 25px;
  }

  @media screen and (min-width: 768px) {
    min-height: 600px;
  }
}


.container-thank-you {
  min-height: 400px;
}

:deep {
  select-container {
    title-label {
      margin-bottom: 5px;
      font-size: 16px;
      font-weight: 700;
      line-height: 33px;
      color: #0c2e3c;
    }
  }
}

.header {
  margin-bottom: 45px;
}

.caption {
  margin-bottom: 18px;
  color: $blue-darker;

  span {
    font-weight: 900;
  }
}

.list {
  margin-left: 35px;
  margin-bottom: 20px;
  font-family: $gotham, sans-serif;
  font-weight: 400;
  font-size: 18px;
  line-height: 35px;
}

.hint {
  color: $blue;
  font-size: 16px;
  font-style: italic;
  margin-bottom: 42px;
  margin-top: 12px;

  span {
    color: $red;
    font-size: 20px;
  }

  @media screen and (max-width: 1024px) {
    margin-bottom: 22px;
    margin-top: 20px;
  }
}

.input-row {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;

  :deep {
    .select-container {
      .title-label {
        margin-bottom: 5px;
        font-size: 16px;
        font-weight: 700;
        line-height: 33px;
        color: #0c2e3c;
      }
    }
  }

  div:first-child {
    margin-right: 37px;
  }

  &--short {
    max-width: 410px;

    div:first-child {
      margin-right: 0;
    }
  }

  @media screen and (max-width: 768px) {
    flex-direction: column;

    div:first-child {
      margin-bottom: 16px;
      margin-right: 0;
    }

    &--short {
      max-width: 100%;

      div:first-child {
        margin-right: 0;
      }
    }
  }
}

.input-row-checkbox {
  justify-content: flex-start;
}

.section-title {
  font-size: 18px;
  text-transform: uppercase;
  font-weight: 700;
  color: $blue-darker;
  margin-bottom: 20px;
  margin-top: 50px;
}

.radio-row {
  display: flex;
  margin-bottom: 20px;

  @media screen and (max-width: 768px) {
    flex-direction: column;
  }
}

.radio {
  input {
    margin-right: 15px;
  }

  label {
    margin-right: 60px;
    font-size: 15px;
    color: $blue-darker;
  }
}

.role-caption {
  font-size: 16px;
  font-weight: 700;
  line-height: 33px;
  color: $blue-darker;
}

.button {
  margin-top: 60px;

  @media screen and (max-width: 768px) {
    margin-top: 26px;
  }
}

.form-actively-submitting {
  cursor: not-allowed;
}

.input-error {
  color: $red;
  font-size: 18px;
  font-weight: 500;
}

.notice-us-only {
  color: $red;
  font-size: 18px;
  font-weight: 500;
}

.register-error {
  color: $red;
  font-size: 18px;
  font-weight: 500;
}

.star {
  color: $red;
  font-size: 20px;
}

p.extra-margin-bottom {
  margin-bottom: 50px;

  @media screen and (max-width: 425px) {
    margin-top: 25px;
  }
}
</style>
