<template>
  <CheckoutForm/>
</template>

<script>
import CheckoutForm from "@/components/CheckoutForm";
import FormTemplate from "@/components/FormTemplate";
import {mapStores} from "pinia";
import {useCmsStore} from "@/stores/cmsStore";
import {useDesignStore} from "@/stores/designStore";

export default {
  name: "Checkout",
  components: {
    CheckoutForm,
    FormTemplate,
  },
  data() {
    return {
      cms: {
        loaded: false, // set to 'true' when CMS content has been returned from API
        options: {
          Common: true,
          Components: ["checkout_form"],
          LanguageCode: "",
          PageName: "checkout",
          MinistryUpdates: false,
        },
        page: {},
      },
      header: "Checkout",
      subHeader: "",
    };
  },
  computed: {
    ...mapStores(useCmsStore, useDesignStore),
  },
  mounted() {
    this.setTitlePageFromCms()
    this.designStore.setIsLogo(true);
  },
  methods: {
    async fetchCmsData() {
      try {
        await this.cmsStore
            .getPageAndOrCommonAndOrComponents(this.cms.options)
            .then((results) => {
              if (results) {
                this.cms.page = results;
              } else return false;

              this.setTitlePageFromCms(results)
              this.setAllCmsContent(results);
              this.setContentLoaded(true);
              return true;
            });
      } catch (e) {
        console.error(e);
      }
    },

    setContentLoaded(loadedStatus) {
      this.cms.loaded = loadedStatus;
    },
    setAllCmsComponentContent(componentContents) {
      this.cms.comp = componentContents;
    },
    setAllCmsContent(cmsContent) {
      this.cms.page = cmsContent;
      this.setHeaderFromCms(cmsContent);
    },
    setHeaderFromCms(page) {
      this.header = page.fgCheckout;
    },
    setTitlePageFromCms(page) {
      const pageTitle = page && page.fgCheckout ? page.fgCheckout : 'Checkout'

      this.designStore.setTitlePage({
        title: pageTitle,
        sub_title: '',
        img_class: 'bg-storeProduct',
        btn_history: true,
      });
    },
  },
  async created() {
    this.setContentLoaded(false);
    await this.fetchCmsData();
  },
};
</script>

<style lang="scss" scoped></style>
